<template lang="pug">
main
  EditHeader(class='z-[100] fixed inset-x-0 top-0' v-if='!isReadonly')
  .inline-flex.w-full(class='pt-[76px]')
    EditDrawer.sticky(class='top-[0] max-w-[432px]')
    slot
  ClientOnly
    EditLayoutRequiredField(class='z-[101]')
</template>

<script setup lang="ts">
const route = useRoute()
const isReadonly = computed(() => (route.name as string).includes('link'))
const { show } = useRequiredField()
provide('readonly', isReadonly)
</script>

<style scoped></style>
